import Vue from 'vue'
import Router from 'vue-router'
import store from './vuex'
import Home from './components/Home.vue'
import Login from './components/Login.vue'
import Register from './components/Register.vue'
import Dashboard from './components/Dashboard.vue'
import Transcript from './components/Transcript.vue'
import TranscriptDetails from './components/TranscriptDetails.vue'
import AllTranscriptRequest from './components/AllTranscriptRequest.vue'
import AllCertificateRequest from './components/AllCertificateRequest.vue'
import AllProofOfEnglishRequest from './components/AllProofOfEnglishRequest.vue'
import TrackApp from './components/TrackApp.vue'
import Certificate from './components/Certificate.vue'
import ProofOfEnglish from './components/ProofOfEnglish.vue'
import Clearance from './components/Clearance.vue'
import ClearanceRequest from './components/ClearanceRequest.vue'
import Index from './components/session/Index.vue'
import Create from './components/session/CreateSession.vue'
import Edit from './components/session/EditSession.vue'
import indexDepartment from './components/department/Index.vue'
import createDepartment from './components/department/CreateDepartment.vue'
import editDepartment from './components/department/EditDepartment.vue'
import indexDeliverymethod from './components/deliverymethod/Index.vue'
import createDeliverymethod from './components/deliverymethod/CreateDeliveryMethod.vue'
import editDeliverymethod from './components/deliverymethod/EditDeliveryMethod.vue'
import indexClassOfDegree from './components/classofdegree/Index.vue'
import createClassOfDegree from './components/classofdegree/CreateClassOfDegree.vue'
import editClassOfDegree from './components/classofdegree/EditClassOfDegree.vue'
import indexClearanceRequestType from './components/clearancerequesttype/Index.vue'
import createClearanceRequestType from './components/clearancerequesttype/CreateClearanceRequestType.vue'
import editClearanceRequestType from './components/clearancerequesttype/EditClearanceRequestType.vue'
import indexProgramLevel from './components/programlevel/Index.vue'
import createProgramLevel from './components/programlevel/CreateProgramLevel.vue'
import editProgramLevel from './components/programlevel/EditProgramLevel.vue'
import indexRequestDocument from './components/requestdocument/Index.vue'
import createRequestDocument from './components/requestdocument/CreateRequestDocument.vue'
import editRequestDocument from './components/requestdocument/EditRequestDocument.vue'
import indexRequestType from './components/requesttype/Index.vue'
import createRequestType from './components/requesttype/CreateRequestType.vue'
import editRequestType from './components/requesttype/EditRequestType.vue'
import indexStudyMode from './components/studymode/Index.vue'
import createStudyMode from './components/studymode/CreateStudyMode.vue'
import editStudyMode from './components/studymode/EditStudyMode.vue'
import LandingPage from './Layouts/LandingPage.vue'
import AdminLayout from './Layouts/AdminLayout.vue'

import guest from './middleware/guest'
import auth from './middleware/auth'
import isAdmin from './middleware/isAdmin'
import isStudent from './middleware/isStudent'


Vue.use(Router)

const router =  new Router({
    mode: 'history',
    routes: [
        { 
            path: '/', 
            component: Home, 
            meta: {
                layout: LandingPage ,
                middleware: [
                     guest
                ]
            } 
        },
        { 
            path: '/login', 
            component: Login, 
            name: "login", 
            meta: { 
                layout: LandingPage,
                middleware: [
                     guest
                ]
            } 
        },
        { 
            path: '/register', 
            component: Register, 
            meta: { 
                layout: LandingPage,
                middleware: [
                     guest
                ]
            } 
        },
        // {
        //         path: '/transcript',
        //         // name: 'dashboard.transcript',
        //         component: Transcript,
        //         meta: {
        //             // middleware: [
        //             //     // auth,
        //             //     // isStudent
        //             // ]
        //         }
        //     },
        { 
            path: '/dashboard', 
            component: Dashboard, 
            name: 'dashboard',
            meta: { 
                layout: AdminLayout,
                // middleware: [
                //     // auth
                // ]
            },
        },
            // children: [
            {
                path: '/transcript-details',
                // name: 'dashboard.movies',
                component: TranscriptDetails,
                meta: {
                    middleware: [
                        auth,
                        isAdmin
                    ]
                }
            },
            {
                path: '/transcript',
                // name: 'dashboard.transcript',
                component: Transcript,
                meta: {
                    middleware: [
                        auth,
                        isStudent
                    ]
                }
            },
            { 
                path: '/all-transcript-request', 
                component: AllTranscriptRequest, 
                meta: { 
                    layout: AdminLayout,
                    middleware: [
                        auth,
                        isAdmin
                    ]
                } 
            },
            { 
                path: '/track-app', 
                component: TrackApp, 
                meta: { 
                    layout: AdminLayout,
                    middleware: [
                        auth,
                        isStudent
                    ] 
                } 
            },
            { 
                path: '/proof-of-english', 
                component: ProofOfEnglish, 
                meta: { 
                    layout: AdminLayout,
                    middleware: [
                        auth,
                        isStudent
                    ] 
                } 
            },
            { 
                path: '/all-proof-of-english-request', 
                component: AllProofOfEnglishRequest, 
                meta: { 
                    layout: AdminLayout,
                     middleware: [
                        auth,
                        isAdmin
                    ]  
                } 
            },
            { 
                path: '/certificate', 
                component: Certificate, 
                meta: { 
                    layout: AdminLayout,
                    middleware: [
                        auth,
                        isStudent
                    ]   
                } 
            },
            { 
                path: '/all-certificate-request', 
                component: AllCertificateRequest, 
                meta: { 
                    layout: AdminLayout,
                     middleware: [
                        auth,
                        isAdmin
                    ]    
                } 
            },
            { 
                path: '/clearance', 
                component: Clearance, 
                meta: { 
                    layout: AdminLayout,
                    middleware: [
                        auth,
                        isStudent
                    ]     
                } 
            },
            { 
                path: '/clearance-request', 
                component: ClearanceRequest, 
                meta: { 
                    layout: AdminLayout,
                     middleware: [
                        auth,
                        isStudent
                    ]    
                } 
            },
            {
                path: '/session',
                component: Index,
                meta: {
                    layout: AdminLayout,
                    middleware: [
                        auth,
                        isAdmin
                    ]   
                }
            },
            {
                path: '/session/create',
                component: Create,
                meta: {
                    layout: AdminLayout,
                    middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                name: 'edit',
                path: '/session/edit/:id',
                component: Edit,
                meta: {
                    layout: AdminLayout,
                    middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                path: '/department',
                name: 'indexDepartment',
                component: indexDepartment,
                meta: {
                    layout: AdminLayout,
                    middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                path: '/department/create',
                component: createDepartment,
                meta: {
                    layout: AdminLayout,
                    middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                name: 'editdept',
                path: '/department/edit/:id',
                component: editDepartment,
                meta: {
                    layout: AdminLayout,
                    middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                path: '/deliverymethod',
                component: indexDeliverymethod,
                meta: {
                    layout: AdminLayout,
                     middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                path: '/deliverymethod/create',
                component: createDeliverymethod,
                meta: {
                    layout: AdminLayout,
                     middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                name: 'editdel',
                path: '/deliverymethod/edit/:id',
                component: editDeliverymethod,
                meta: {
                    layout: AdminLayout,
                     middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
                {
                path: '/class-of-degree',
                component: indexClassOfDegree,
                meta: {
                    layout: AdminLayout,
                    middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                path: '/class-of-degree/create',
                component: createClassOfDegree,
                meta: {
                    layout: AdminLayout,
                    middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                name: 'editcdegree',
                path: '/class-of-degree/edit/:id',
                component: editClassOfDegree,
                meta: {
                    layout: AdminLayout,
                    middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                path: '/clearance-request-type',
                component: indexClearanceRequestType,
                meta: {
                    layout: AdminLayout,
                     middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                path: '/clearance-request-type/create',
                component: createClearanceRequestType,
                meta: {
                    layout: AdminLayout,
                     middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                name: 'editcrequesttype',
                path: '/clearance-request-type/edit/:id',
                component: editClearanceRequestType,
                meta: {
                    layout: AdminLayout,
                     middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                path: '/program-level',
                component: indexProgramLevel,
                meta: {
                    layout: AdminLayout,
                     middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                path: '/program-level/create',
                component: createProgramLevel,
                meta: {
                    layout: AdminLayout,
                     middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                name: 'editplevel',
                path: '/program-level/edit/:id',
                component: editProgramLevel,
                meta: {
                    layout: AdminLayout,
                     middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                path: '/request-document',
                component: indexRequestDocument,
                meta: {
                    layout: AdminLayout,
                    middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                path: '/request-document/create',
                component: createRequestDocument,
                meta: {
                    layout: AdminLayout,
                    middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                name: 'editrdoc',
                path: '/request-document/edit/:id',
                component: editRequestDocument,
                meta: {
                    layout: AdminLayout,
                    middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                path: '/request-type',
                component: indexRequestType,
                meta: {
                    layout: AdminLayout,
                     middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                path: '/request-type/create',
                component: createRequestType,
                meta: {
                    layout: AdminLayout,
                     middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                name: 'editrtype',
                path: '/request-type/edit/:id',
                component: editRequestType,
                meta: {
                    layout: AdminLayout,
                     middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                path: '/study-mode',
                component: indexStudyMode,
                meta: {
                    layout: AdminLayout,
                    middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                path: '/study-mode/create',
                component: createStudyMode,
                meta: {
                    layout: AdminLayout,
                    middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            },
            {
                name: 'editsmode',
                path: '/study-mode/edit/:id',
                component: editStudyMode,
                meta: {
                    layout: AdminLayout,
                    middleware: [
                        auth,
                        isAdmin
                    ]  
                }
            }
        // ], 
        
        // }

    ]
})

function middlewarePipeline (context, middleware, index) {
    const nextMiddleware = middleware[index]

    if(!nextMiddleware){
        return context.next 
    }

    return () => {
        const nextPipeline = middlewarePipeline(
            context, middleware, index + 1
        )

        nextMiddleware({ ...context, next: nextPipeline })

    }
}


router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        next,
        store
    }
    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
})


export default router