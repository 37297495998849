export default function isStudent({ next, store }) {
     console.log(store.state.user.role_id)
    if ((store.state.user.role_id)  && (store.state.user.role_id != 2) ) {
    // if (!store.state.isStudent) {
        return next({
            name: 'dashboard'
        })
    }

    return next()
}