<template>
    <div>
        <div class="flex h-screen bg-white-200 items-center justify-center  mt-32 mb-32">
            <div class="grid bg-white rounded-lg shadow-xl w-11/12 md:w-9/12 lg:w-1/2">

                <div class="flex justify-center">
                    <div class="flex">
                        <h1 class="text-gray-600 font-bold md:text-2xl text-xl">Clearance</h1>
                    </div>
                </div>
                <form @submit="storeClearanceRequest">
                    <div class="grid grid-cols-1 mt-5 mx-7">
                        <div class="grid grid-cols-1">
                            <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Choose Clearance Type</label>
                        <select 
                        v-model="formData.clearancerequesttype_id"
                        name="clearancerequesttype_id"
                        class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent">
                        
                            <option
                            v-for="clearancerequesttype in clearancerequesttypes" 
                            :key="clearancerequesttype.id" 
                            :value="clearancerequesttype.id">
                                {{ clearancerequesttype.name }}
                            </option>
                        </select>
                        </div>
                    </div>
                    <div class="grid grid-cols-1 mt-5 mx-7">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Receipt Number</label>
                        <input name="receipt_number" 
                        v-model="formData.receipt_number"
                        class="py-2 px-3 rounded-lg 
                        border-2 border-purple-300 
                        mt-1 focus:outline-none focus:ring-2 
                        focus:ring-purple-600 focus:border-transparent" type="text" placeholder="Receipt Number" />
                    </div>
                    
                    <div class="grid grid-cols-1 mt-5 mx-7">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Upload Receipt</label>
                        <input 
                        
                        class="py-2 px-3 rounded-lg 
                        border-2 border-purple-300 
                        mt-1 focus:outline-none focus:ring-2 
                        focus:ring-purple-600 focus:border-transparent" @change="onFileChange" type="file" id="receipt_image" name="receipt_image" />
                    </div>

                    <div class='flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5'>
                    <!-- <button class='w-auto bg-gray-500 hover:bg-gray-700 rounded-lg shadow-xl font-medium text-white px-4 py-2'>Cancel</button> -->
                    <button type="submit" class='w-auto bg-purple-500 hover:bg-purple-700 rounded-lg shadow-xl font-medium text-white px-4 py-2'>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    components: {

    },
      data() {
        return {
            clearancerequesttypes: [],
            // clearancerequesttype_id: '',
            formFields: {
                clearancerequesttype_id: '',
                receipt_number: '',
                receipt_image: '',
                status: 0,
                success: ''
            }
        };
    },
    created(){
        this.getClearanceRequestType();
    },
    methods: {
        getClearanceRequestType(){
            axios.get("/clearance-request-type")
                 .then(res => {
                    this.clearancerequesttypes = res.data.data;
                }).catch(error => {
                    console.log(error)
                });
        },
        onFileChange(event){
            this.formFields.receipt_image = event.target.files[0];
        },
        storeClearanceRequest(){
            let formData = new FormData();
            
            formData.append("receipt_image", this.formFields.receipt_image);
            formData.append("receipt_number", this.formFields.receipt_number);
            formData.append("clearancerequesttype_id", this.formFields.clearancerequesttype_id);

            // send upload request
            // axios.post("/clearance-request", {
            //     receipt_number: this.form.receipt_number,
            //     receipt_image: this.form.receipt_image,
            //     clearancerequesttype_id: this.form.clearancerequesttype_id,
            //     currentObj.success = response.data.success;
            //             currentObj.filename = "";
            // }).then(res => {
            //     console.log(res);
            //     this.$router.push('/dashboard');
            // });
            axios.post('/clearance-request', formData)
                .then(res => {
                    console.log(res);
                }).catch(error => {
                    console.log(error);
                    this.$router.push('/dashboard');
                });
        },
    }
} 
</script>