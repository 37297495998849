<template>
    <div class="mb-4 mt-10">
        <div class="flex flex-col h-screen bg-gray-100">
            <!-- Auth Card Container -->
            <div class="grid place-items-center mx-2 my-20 sm:my-auto">
                <!-- Auth Card -->
                <div class="w-11/12 p-12 sm:w-8/12 md:w-6/12 lg:w-5/12 2xl:w-4/12 
                    px-6 py-10 sm:px-10 sm:py-6 
                    bg-white rounded-lg shadow-md lg:shadow-lg">

                    <!-- Card Title -->
                    <h2 class="text-center font-semibold text-3xl lg:text-4xl text-gray-800">
                        Create Profile Account
                    </h2>

                    <form @submit.prevent="handleSubmit">
                        <!-- Email Input -->
                        <label for="first_name" class="block text-xs font-semibold text-gray-600 uppercase">First Name</label>
                        <input id="first_name" type="first_name" v-model="first_name" placeholder="First Name" autocomplete="first_name"
                            class="block w-full py-3 px-1 mt-2 
                            text-gray-800 appearance-none 
                            border-b-2 border-gray-100
                            focus:text-gray-500 focus:outline-none focus:border-gray-200"
                            required />
                        
                        <label for="first_name" class="block text-xs font-semibold text-gray-600 uppercase">Last Name</label>
                        <input id="last_name" type="last_name" v-model="last_name" placeholder="Last Name" autocomplete="last_name"
                            class="block w-full py-3 px-1 mt-2 
                            text-gray-800 appearance-none 
                            border-b-2 border-gray-100
                            focus:text-gray-500 focus:outline-none focus:border-gray-200"
                            required />

                        <label for="email" class="block text-xs font-semibold text-gray-600 uppercase">E-mail</label>
                        <input id="email" type="email" v-model="email" placeholder="e-mail address" autocomplete="email"
                            class="block w-full py-3 px-1 mt-2 
                            text-gray-800 appearance-none 
                            border-b-2 border-gray-100
                            focus:text-gray-500 focus:outline-none focus:border-gray-200"
                            required />

                        <label for="phone_number" class="block text-xs font-semibold text-gray-600 uppercase">Phone Number</label>
                        <input id="phone_number" type="phone_number" v-model="phone_number" placeholder="phone number" autocomplete="phone_number"
                            class="block w-full py-3 px-1 mt-2 
                            text-gray-800 appearance-none 
                            border-b-2 border-gray-100
                            focus:text-gray-500 focus:outline-none focus:border-gray-200"
                            required />
                        
                        <label for="dob" class="block text-xs font-semibold text-gray-600 uppercase">Date of birth</label>
                        <input id="dob" type="date" v-model="dob" placeholder="date of birth" autocomplete="dob"
                            class="block w-full py-3 px-1 mt-2 
                            text-gray-800 appearance-none 
                            border-b-2 border-gray-100
                            focus:text-gray-500 focus:outline-none focus:border-gray-200"
                            required />

                        <label for="gender" class="block text-xs font-semibold text-gray-600 uppercase">Gender</label>
                        <input id="gender" type="gender" v-model="gender" placeholder="gender" autocomplete="gender"
                            class="block w-full py-3 px-1 mt-2 
                            text-gray-800 appearance-none 
                            border-b-2 border-gray-100
                            focus:text-gray-500 focus:outline-none focus:border-gray-200"
                            required />

                        <label for="matric_number" class="block text-xs font-semibold text-gray-600 uppercase">Matric Number</label>
                        <input id="matric_number" type="matric_number" v-model="matric_number" placeholder="matric number" autocomplete="matric_number"
                            class="block w-full py-3 px-1 mt-2 
                            text-gray-800 appearance-none 
                            border-b-2 border-gray-100
                            focus:text-gray-500 focus:outline-none focus:border-gray-200"
                            required />

                        <label for="department" class="block text-xs font-semibold text-gray-600 uppercase">Department</label>
                        <!-- <select name="" id="" class="block w-full py-3 px-1 mt-2 
                            text-gray-800 appearance-none 
                            border-b-2 border-gray-100
                            focus:text-gray-500 focus:outline-none focus:border-gray-200"
                            required >
                            <option value="">select department</option>
                            <option value=""></option>
                        </select> -->

                        <!-- Password Input -->
                        <label for="password" class="block mt-2 text-xs font-semibold text-gray-600 uppercase">Password</label>
                        <input id="password" type="password" v-model="password" placeholder="password" autocomplete="current-password"
                            class="block w-full py-3 px-1 mt-2 mb-4
                            text-gray-800 appearance-none 
                            border-b-2 border-gray-100
                            focus:text-gray-500 focus:outline-none focus:border-gray-200"
                            required />

                        <label for="password_confirmation" class="block mt-2 text-xs font-semibold text-gray-600 uppercase">Password Confirmation</label>
                        <input id="password_confirmation" type="password" v-model="password_confirmation" placeholder="password confirmation" autocomplete="current-password"
                            class="block w-full py-3 px-1 mt-2 mb-4
                            text-gray-800 appearance-none 
                            border-b-2 border-gray-100
                            focus:text-gray-500 focus:outline-none focus:border-gray-200"
                            required />

                        <!-- Auth Buttton -->
                        <button type="submit"
                            class="w-full py-3 mt-10 bg-gray-800 rounded-sm
                            font-medium text-white uppercase
                            focus:outline-none hover:bg-gray-700 hover:shadow-none">
                            Create Profile
                        </button>

                        <!-- Another Auth Routes -->
                        <!-- <div class="sm:flex sm:flex-wrap mt-8 sm:mb-4 text-sm text-center">
                            <a href="forgot-password" class="flex-2 underline">
                                Forgot password?
                            </a>

                            <p class="flex-1 text-gray-500 text-md mx-4 my-1 sm:my-auto">
                                or
                            </p>
                
                            <a href="register" class="flex-2 underline">
                                Create an Account
                            </a>
                        </div> -->
                    </form>
                </div>
            </div>
        </div>
    </div>
    
</template>


<script>
import axios from 'axios'

export default {
    
    data() {
        return {
            first_name: '',
            last_name: '',
            // department_id: '',
            role_id: 2,
            email: '',
            phone_number: '',
            dob: '',
            gender: '',
            matric_number: '',
            password: '',
            password_confirmation: ''
        }
    },
    methods: {
       async handleSubmit() { 
            // console.log(data);

          await axios.post('register', {
              
                first_name: this.first_name,
                last_name: this.last_name,
                role_id: this.role_id,
                email: this.email,
                phone_number: this.phone_number,
                dob: this.dob,
                gender: this.gender,
                matric_number: this.matric_number,
                password: this.password,
                password_confirmation: this.password_confirmation   
          });

        //   console.log(response);
          this.$router.push('/login');
              
        }
    }
}
</script>

<style>
  /* *{
      margin: 0;
      padding: 4px;
  }

    form {
        background-color: rgb(158, 146, 131);
        border-radius: 10px;
    }

  h1 {
      font-size: 30px;
      color: #fff;
      margin-top: 100px;
      margin-bottom: 20px;
  }
  p {
      font-size: 17px;
      color: #fff;
  }
  .glyphicon-pencil {
      font-size: 35px;
      color: #fff;
      float: left;
      margin-top: 20px;
  }

  .col-md-5{
      margin-top: 100px;
      box-shadow: -1px 1px 60px 10px black;
      background: #fff;
  }
  .label {
      font-weight: normal;
      margin-top: 15px;
      color: #fff;
      font-size: 20px;
  }
  .form-control {
      background: transparent;
      border-radius: 0px;
      border: 0px;
      border-bottom: 1px solid #fff;
      font-size: 18px;
      margin-top: 15px;
      height: 40px;
      color: #fff;
  } */
</style>