<template>
    <div>
        <div class="flex h-screen bg-white-200 items-center justify-center  mt-32 mb-32">
            <div class="grid bg-white rounded-lg shadow-xl w-11/12 md:w-9/12 lg:w-1/2">

                <div class="flex justify-center">
                <div class="flex">
                    <h1 class="text-gray-600 font-bold md:text-2xl text-xl">Certificate Request Form</h1>
                </div>
                </div>

                 <div class="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 mt-5 mx-7">
                    <fieldset class="relative z-0 w-full p-px mb-5">
                        <legend class="absolute text-gray-500 transform scale-75 -top-3 origin-0">Request Type</legend>
                        <div class="block pt-3 pb-2 space-x-4">
                        <label>
                            <input
                            v-model="form.requesttype_id"
                            type="radio"
                            name="radio"
                            value="1"
                            class="mr-2 text-black border-2 border-gray-300 focus:border-gray-300 focus:ring-black"
                            />
                            Soft Copy (mail)
                        </label>
                        <label>
                            <input
                            v-model="form.requesttype_id"
                            type="radio"
                            name="radio"
                            value="2"
                            class="mr-2 text-black border-2 border-gray-300 focus:border-gray-300 focus:ring-black"
                            />
                            Physical
                        </label>
                        <label>
                            <input
                            v-model="form.requesttype_id"
                            type="radio"
                            name="radio"
                            value="3"
                            class="mr-2 text-black border-2 border-gray-300 focus:border-gray-300 focus:ring-black"
                            />
                            Home Address
                        </label>
                        </div>
                        <!-- <span class="text-sm text-red-600 hidden" id="error">Option has to be selected</span> -->
                    </fieldset>
                 </div>

                 <div class="grid grid-cols-1 mt-5 mx-7">
                    <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Destination Institution/Organization</label>
                    <input 
                    v-model="form.destination_institution" 
                    class="py-2 px-3 rounded-lg 
                    border-2 border-purple-300 
                    mt-1 focus:outline-none focus:ring-2 
                    focus:ring-purple-600 focus:border-transparent" type="text" placeholder="Destination Institution/Organization" />
                </div>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 mt-5 mx-7">
                    <div class="grid grid-cols-1">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Address 1</label>
                        <input v-model="form.address1" class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" type="text" placeholder="Address 1" />
                    </div>
                    <div class="grid grid-cols-1">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Address 2</label>
                        <input v-model="form.address2" class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" type="text" placeholder="Address 2" />
                    </div>
                </div>

                 <div class="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 mt-5 mx-7">
                    <div class="grid grid-cols-1">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Country</label>
                    <select 
                     v-model="form.country_id"
                        @change="getStates()"
                        name="country" 
                    class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent">
                        <option
                        v-for="country in countries" 
                        :key="country.id" 
                        :value="country.id">
                        {{ country.name }}
                        </option>
                    </select>
                    </div>
                    <div class="grid grid-cols-1">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">State</label>
                    <select 
                    v-model="form.state_id"
                        name="state"
                    class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent">
                        <option
                        v-for="state in states" 
                        :key="state.id" 
                        :value="state.id">
                        {{ state.name }}
                        </option>
                    </select>
                    </div>
                </div>
                
                 <div class="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 mt-5 mx-7">
                    <div class="grid grid-cols-1">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">City</label>
                        <input v-model="form.city" class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" type="text" placeholder="City" />
                    </div>
                    <div class="grid grid-cols-1">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Zip Code</label>
                        <input v-model="form.zipcode" class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" type="text" placeholder="Zip Code" />
                    </div>
                </div>

                 <div class="grid grid-cols-1 mt-5 mx-7">
                    <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Destination Contact Number</label>
                    <input v-model="form.destination_contact_number" class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" type="text" placeholder="Destination Contact Number" />
                </div>

                <div class='flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5'>
                <!-- <button class='w-auto bg-gray-500 hover:bg-gray-700 rounded-lg shadow-xl font-medium text-white px-4 py-2'>Cancel</button> -->
                <button @click.prevent="storeCertificate" type="submit" class='w-auto bg-purple-500 hover:bg-purple-700 rounded-lg shadow-xl font-medium text-white px-4 py-2'>Submit Certificate Request</button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    components: {

    },
      data() {
        return {
            countries: [],
            states: [],
            requesttypes: [],
            form: {
                address1: '',
                address2: '',
                requesttype_id: '',
                country_id: '',
                state_id: '',
                destination_institution: '',
                city: '',
                zipcode: '',
                destination_contact_number: '',
                reference_number: '',
                status: 0
            }
        };
    },
    created(){
        this.getCountries();
        this.getStates();
        this.getRequestTypes();
    },
    methods: {
        getCountries(){
            axios.get("/certificate/countries")
                 .then(res => {
                    this.countries = res.data;
                }).catch(error => {
                    console.log(error)
                });
        },
        getStates(){
            axios.get("/certificate/"+ this.form.country_id + "/states")
                 .then(res => {
                     console.log(this.form.country_id);
                    this.states = res.data;
                }).catch(error => {
                    console.log(error)
                });
        },
        getRequestTypes(){
            axios.get("/certificate/requesttypes")
                 .then(res => {
                    this.requesttype = res.data;
                }).catch(error => {
                    console.log(error)
                });
        },
        storeCertificate(){
            axios.post("/certificate", {
                address1: this.form.address1,
                address2: this.form.address2,
                requesttype_id: this.form.requesttype_id,
                country_id: this.form.country_id,
                state_id: this.form.state_id,
                destination_institution: this.form.destination_institution,
                city: this.form.city,
                zipcode: this.form.zipcode,
                destination_contact_number: this.form.destination_contact_number,
                reference_number: this.form.reference_number =  'T' + Math.floor(Math.random()*(9999999999-1000000000+1)+1000000000)
            }).then(res => {
                console.log(res);
                this.$router.push('/dashboard');
            });
        }
    }
}
</script>