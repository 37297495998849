import Vue from 'vue'
import Vuex from 'vuex'
  import axios from 'axios'

Vue.use(Vuex);

const state = {
        user: JSON.parse(localStorage.getItem('user')),
        token: JSON.parse(localStorage.getItem('token')),
        isAdmin: false,
        isStudent: false
};

const store = new Vuex.Store({
    state,
    getters: {
        user: (state) => {
            // return state.user = JSON.parse(localStorage.getItem('user'));
            return state.user; // Get current value of user
        },

        token: (state) => {
            // return state.token = JSON.parse(localStorage.getItem('token'));
            return state.token;
    
        },
        isStudent: (state) => {
            // return state.token = JSON.parse(localStorage.getItem('token'));
            return state.isStudent;
    
        },

        isAdmin: (state) => {
            // return state.token = JSON.parse(localStorage.getItem('token'));
            return state.isAdmin;
    
        }
    },

    actions: {
        // user(context, user) {
        //     context.commit('user', user);
        // },

          async signIn({commit},credentials) {
              const res = await axios.post('login',credentials)
        // .catch(function (error) {
            // if (error.response.status == 404) {
              
            // }
            // if (error.response.status == 200) {
                
            //   }
             
        //   }); 

        //   if(res.data.role == 1){
            localStorage.setItem('token',JSON.stringify(res.data.token));
            localStorage.setItem('user',JSON.stringify(res.data.user));
            // localStorage.setItem('SM_email',JSON.stringify(res.data.email));
            commit('user',res.data);
        //   }
         console.log(res.data);
          return res;       

    },
     async logout({commit}){
        const res  = await commit('logout');
        return res;
    }
    },

    mutations: {

         user(state,data){
        state.user = data.user;
        state.token = data.token
        if(data.user.role_id == 2 ){
             state.isStudent = true;
        }
    },
        logout(state) {
        state.token = null;
        state.user = {};
        localStorage.removeItem('token');
        localStorage.removeItem('user');
    }
    }
});


export default store;