<template>
    <div>      

        <div class="flex h-auto bg-gray-50 font-roboto">
            <div class="hidden md:block min-w-1/5">
                <Sidebar />
            </div>
            

            <div class="w-4/5 flex-1 flex flex-col overflow-hidden">
                <Header />

                <main class="flex-1 overflow-x-hidden overflow-y-auto bg-gray-50">
                    <div class="container mx-auto px-6 py-8">
                        <router-view></router-view> 
                    </div>
                </main>                
            </div>
        </div>

        <div class="">
            <Footer />
        </div>
        
    </div>
</template>

<script>
import Sidebar from '../components/Sidebar'
import Header from '../components/Header'
export default {

    components: {
        Sidebar,
        Header
    }
    
}
</script>