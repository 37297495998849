<template>
    <div>
            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="mb-8 border-b border-gray-200">
                <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                    <div class="flex items-center justify-between flex-wrap">
                        <div class="w-0 flex-1 flex items-center">                            
                            <p class="ml-3 font-medium text-gray-700 truncate">
                                All Request Documents
                            </p>
                        </div>
                        <div class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                            <router-link to="/request-document/create" class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-green-600 bg-white hover:bg-green-50">
                            New Request Document
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="flex flex-col">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-gray-50">
                                    <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        #
                                    </th>
                                   
                                   <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Name
                                    </th>

                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        <span class="sr-only">Edit</span>
                                    </th>

                                     <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        <span class="sr-only">Delete</span>
                                    </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr  v-for="requestdocument in requestdocuments" :key="requestdocument.id">
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            {{ requestdocument.id }}
                                        </td>
                                         <td class="px-6 py-4 whitespace-nowrap">
                                            {{ requestdocument.name }}
                                        </td>
                                        
                                        <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            
                                            <router-link :to="{name: 'editrdoc', params: {id: requestdocument.id}}" class="text-indigo-600 hover:text-indigo-900">Edit</router-link>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            
                                            <button @click="deleteRequestDocument(requestdocument.id)" class="text-indigo-600 hover:text-indigo-900">Delete</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
        data() {
            return {
                requestdocuments: [],
                showMessage: false,
                message: ''
            }
        },
        methods: {
            getRequestDocuments() {
                axios.get('requestdocuments').then((response) => {
                    this.requestdocuments = response.data.data
                    // console.log(response.data)
                }).catch((errors) => {
                    console.log(errors);
                });
            },

            deleteRequestDocument(id) {
                axios.delete("/requestdocuments/"+ id)
                    .then(res => {
                        this.showMessage = true;
                        this.message = res.data;
                        this.getRequestDocuments();
                    })

                // this.$swal({
                // title: 'Are you sure?',
                // text: "You won't be able to revert this!",
                // type: 'warning',
                // showCancelButton: true,
                // confirmButtonColor: '#3085d6',
                // cancelButtonColor: '#d33',
                // confirmButtonText: 'Yes, delete it!'
                // }).then((result) => {
                // if (result.value) {
                //     axios.delete('requestdocuments/{id}' + requestdocument_id).then((response) => {
                //             this.getRequestDocuments()
                //             console.log(response)
                //         }).catch((errors) => {
                //             console.log(errors)
                //         })
                //         this.$swal(
                //             'Deleted!',
                //             'Your file has been deleted.',
                //             'success'
                //         )
                // }
                // });
            
        }
    },

        mounted() {
            this.getRequestDocuments()
        }
    }
</script>
