
<template>
    <div>
        <div class="container">
            <h2 class="pl-5 font-semibold text-xl text-gray-800 leading-tight">
               Dashboard
            </h2>
        </div>

        
        <div class="flex items-center">
            <div class="container max-w-6xl px-5 mx-auto my-8">
                <div class="grid gap-7 sm:grid-cols-2 lg:grid-cols-4">
                    
                    <div class="p-5 bg-green-100 rounded shadow-sm">                        
                        <div class="flex flex-col items-center pt-1">
                            <div class="text-base text-gray-400 ">Total Users</div>
                            <div class="text-2xl font-bold text-gray-900 ">9850</div>
                        </div>
                    </div>

                    <div class="p-5 bg-red-100 rounded shadow-sm">
                        <div class="flex flex-col items-center pt-1">
                            <div class="text-base text-gray-400 ">Total Conferences</div>
                            <div class="text-2xl font-bold text-gray-900 ">752</div>                            
                        </div>
                    </div>
                    <div class="p-5 bg-pink-100 rounded shadow-sm">                        
                        <div class="flex flex-col items-center pt-1">
                            <div class="text-base text-gray-400 ">Abstracts</div>
                            <div class="text-2xl font-bold text-gray-900 ">1375</div>                            
                        </div>
                    </div>
                    <div class="p-5 bg-indigo-100 rounded shadow-sm">
                        <div class="flex flex-col items-center pt-1">
                            <div class="text-base text-gray-400 ">Registrations</div>
                            <div class="text-2xl font-bold text-gray-900 ">1375</div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {mapGetters} from 'vuex'
// import axios from 'axios'

    export default {

        // data() {
        //     return {
        //         user: null
        //     }
        // },
        async created() {
            // const response = await axios.get('user');

            // console.log(response);
            // this.user = response.data
            // this.$store.dispatch('user', response.data);
        },
        methods: {
            HandleClick() {
                localStorage.removeItem('token');
                this.$store.dispatch('user', null);
                this.$router.push('/');
            }
        },
        computed: {
            ...mapGetters(['user'])
        }
    }
</script>