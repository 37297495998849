<template>
  <div>
    <div class="flex items-center justify-between">
                    <div class="flex items-center justify-center">
                    <div class="flex items-center justify-center text-3xl font-bold text-true-gray-800">
                        <!-- <svg class="w-10 h-10 mr-1 text-blue-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"></path>
                        </svg> -->
                         <img src="../assets/ibbu.jpg" width="250" alt="" srcset="">
                       
                    </div>   
                    <div class="hidden lg:flex items-center justify-center antialiased lg:ml-20 pt-1">
                        <a href="#" class="flex items-center justify-center mr-10 text-base text-gray-700 text-opacity-90 font-medium tracking-tight hover:text-cool-gray-600 transition duration-150 ease-in-out">
                        Transcript Request 
                        <!-- <svg class="w-3.5 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M19 9l-7 7-7-7"></path>
                        </svg> -->
                        </a>
                        <a href="#" class="flex items-center justify-center mr-10 text-base text-gray-700 text-opacity-90 font-medium tracking-tight hover:text-cool-gray-600 transition duration-150 ease-in-out">
                        Clearance
                        <!-- <svg class="w-3.5 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M19 9l-7 7-7-7"></path>
                        </svg> -->
                        </a>
                        <a href="#" class="flex items-center justify-center mr-10 text-base text-gray-700 text-opacity-90 font-medium tracking-tight hover:text-cool-gray-600 transition duration-150 ease-in-out">
                        Proof of English Proficiency
                        </a>
                         <a href="#" class="flex items-center justify-center mr-10 text-base text-gray-700 text-opacity-90 font-medium tracking-tight hover:text-cool-gray-600 transition duration-150 ease-in-out">
                        Certificate Request
                        </a>
                    </div>
                    </div>
                    <div class="hidden md:flex items-center justify-center">
                    <router-link to="/login" class="mr-5 text-lg font-medium text-true-gray-800 hover:text-cool-gray-700 transition duration-150 ease-in-out">Login</router-link>
                    <router-link to="/register" class="px-6 py-3 rounded-3xl font-medium bg-gradient-to-b from-gray-900 to-black text-white outline-none focus:outline-none hover:shadow-md hover:from-true-gray-900 transition duration-200 ease-in-out">Sign Up</router-link>
                    </div>
                </div>
    <!-- <nav id="header" class="fixed bg-black text-white w-full z-20 top-0">
      <div id="progress" class="h-1 z-40 top-0"
        style="background:linear-gradient(to right, #FFC100 var(--scroll), transparent 0);"></div>
      <div class="w-full md:max-w-4xl mx-auto flex flex-wrap items-center justify-between mt-0 py-3">
        <div class="block lg:hidden pl-8 p-2">
          <a class="text-yellow-300 font-bold text-base no-underline hover:no-underline" href="#">
            TechFest
          </a>
        </div>
        <div class="block lg:hidden pr-4">
          <button id="nav-toggle" class="flex items-center px-3 py-2 text-yellow-300 border-gray-600 focus:outline-none">
              <svg fill="text-yellow-300" viewBox="0 0 20 20" class="w-6 h-6 fill-current">
                <title>Menu</title>
                <path fill-rule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                  clip-rule="evenodd"></path>
              </svg>
            </button>
        </div>
        <div class="w-full flex-grow p-4 lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 bg-black md:bg-transparent z-20"
          id="nav-content">
          <ul class="list-reset lg:flex justify-center flex-1 items-center">
            <li class="mr-3">
              <a class="border-b-0 md:border-b-4 border-yellow-300 inline-block py-2 px-4 text-yellow-300 font-bold"
                href="#">Home</a>
            </li>
            <li class="mr-3">
              <a class="inline-block hover:text-yellow-300 hover:text-underline py-2 px-4" href="#">Events</a>
            </li>
            <li class="mr-3">
              <a class="inline-block hover:text-yellow-300 hover:text-underline py-2 px-4" href="#">Team</a>
            </li>
            <li class="mr-3">
              <a class="inline-block hover:text-yellow-300 hover:text-underline py-2 px-4" href="#">About</a>
            </li>
            <li class="mr-3">
              <a class="inline-block hover:text-yellow-300 hover:text-underline py-2 px-4" href="#">Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </nav> -->
  </div>
</template>

<script>


	var h = document.documentElement,
    b = document.body,
    st = 'scrollTop',
    sh = 'scrollHeight',
    progress = document.querySelector('#progress'),
    scroll;
var scrollpos = window.scrollY;
var header = document.getElementById("header");
var navcontent = document.getElementById("nav-content");

document.addEventListener('scroll', function () { 
    scroll = (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;
    progress.style.setProperty('--scroll', scroll + '%');
    scrollpos = window.scrollY;

    if (scrollpos > 10) {
        header.classList.add("bg-black");
        navcontent.classList.remove("bg-black");
        navcontent.classList.add("bg-black");
    } else {
        header.classList.remove("bg-black");
        navcontent.classList.remove("bg-black");
        navcontent.classList.add("bg-black");

    }

});

// document.getElementById('nav-toggle').onclick = function () {
//     document.getElementById("nav-content").classList.toggle("hidden");
// }

export default {
    components: {

    },

    
}

</script>