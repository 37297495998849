export default function isAdmin({ next, store }) {
     if ((store.state.user.role_id)  && (store.state.user.role_id != 1) ) {

    // if (!store.state.isAdmin) {
        return next({
            name: 'dashboard'
        })
    }

    return next()
}