<template>
    <div>

        <div class="grid grid-cols-1 md:grid-cols-3">
            <div v-for="(clearancerequesttype, index) in clearancerequesttypes" :key="index" class="max-w-md py-4 px-4 mr-3 bg-white shadow-lg rounded-lg my-20">
                 <h2 class="text-gray-800 text-3xl font-semibold">{{ clearancerequesttype.name }}</h2>
                <p class="mt-2 text-gray-600 mb-5">{{ clearancerequesttype.description }}</p>
                <!-- <button class="text-xl font-medium text-indigo-500">Do Clearance</button> -->

                <router-link to="/clearance-request" class="w-full py-3 mt-10 bg-gray-800 rounded-md
                    font-medium text-white uppercase
                    focus:outline-none hover:bg-gray-700 hover:shadow-none">
                    Do Clearance
                </router-link>
                <!-- <button type="submit"
                    class="w-full py-3 mt-10 bg-gray-800 rounded-sm
                    font-medium text-white uppercase
                    focus:outline-none hover:bg-gray-700 hover:shadow-none">
                    Do Clearance
                </button> -->
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
        data() {
            return {
                clearancerequesttypes: {}
            }
        },
        methods: {
            getClearanceRequestType() {
                axios.get('clearance-request-type').then((response) => {
                    this.clearancerequesttypes = response.data.data
                    console.log(response.data)
                }).catch((errors) => {
                    console.log(errors);
                });
            },
            

            getSingleRequest(){
                axios.get('getsingle-clearance-type/' + this.$route.params.id)
                    .then(res => {
                    this.form = res.data[0]
                    // console.log(res);
                }).catch(error => {
                    console.log(error);
                });

                //  axios.get('getsingle-clearance-type').then((response) => {
                //     this.clearancerequesttypes = response.data.data
                //     console.log(response.data)
                // }).catch((errors) => {
                //     console.log(errors);
                // });
            }
    },

        mounted() {
            this.getClearanceRequestType()
        }
    }
</script>


