<template>
    <div class="mb-4">
        <div class="flex flex-col h-screen bg-gray-100">
            <!-- Auth Card Container -->
            <div class="grid place-items-center mx-2 my-20 sm:my-auto">
                <!-- Auth Card -->
                <div class="w-11/12 p-12 sm:w-8/12 md:w-6/12 lg:w-5/12 2xl:w-4/12 
                    px-6 py-10 sm:px-10 sm:py-6 
                    bg-white rounded-lg shadow-md lg:shadow-lg">

                    <!-- Card Title -->
                    <h2 class="text-center font-semibold text-2xl lg:text-2xl text-gray-800">
                        Edit session
                    </h2>

                    <form @submit.prevent="update">
                        <!-- Email Input -->
                        <label for="year" class="block text-xs font-semibold text-gray-600 uppercase">Year</label>
                        <input id="year" type="text" v-model="form.year" placeholder="year" autocomplete="year"
                            class="block w-full py-3 px-1 mt-2 
                            text-gray-800 appearance-none 
                            border-b-2 border-gray-100
                            focus:text-gray-500 focus:outline-none focus:border-gray-200"
                            required />

                        <!-- Auth Buttton -->
                        <button type="submit"
                            class="w-full py-3 mt-10 bg-gray-800 rounded-sm
                            font-medium text-white uppercase
                            focus:outline-none hover:bg-gray-700 hover:shadow-none">
                            Update
                        </button>

                        <!-- Another Auth Routes -->
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
     mounted() {
                this.show()
        },
        data() {
            return {
                form: {
                    year: ''
                }
            }
        },
        methods: {
            show() {
                axios.get('sessions/' + this.$route.params.id)
                    .then(res => {
                    this.form = res.data[0]
                    // console.log(res);
                }).catch(error => {
                    console.log(error);
                });
            },
            update() {
                axios.put("/sessions/"+ this.$route.params.id, {
                    year: this.form.year
                }).then(res => {
                    console.log(res);
                    this.$router.push('/session')
                    this.$toasted.success('session updated successfully')
                }).catch(error => {
                    console.log(error);
                });
            }

        }
    }
</script>
