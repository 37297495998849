<template>
    <div>
        <div class="flex h-screen bg-white-200 items-center justify-center  mt-32 mb-32">
            <div class="grid bg-white rounded-lg shadow-xl w-11/12 md:w-9/12 lg:w-1/2">

                <div class="flex justify-center">
                <div class="flex">
                    <h1 class="text-gray-600 font-bold md:text-2xl text-xl">Track your Application</h1>
                </div>
                </div>

                <div class="grid grid-cols-1 mt-5 mx-7">
                    <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Reference Number</label>
                    <input 
                    v-model="form.reference_number" 
                    class="py-2 px-3 rounded-lg 
                    border-2 border-purple-300 
                    mt-1 focus:outline-none focus:ring-2 
                    focus:ring-purple-600 focus:border-transparent" type="text" placeholder="reference number" />
                </div>

                <div class='flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5'>
                <!-- <button class='w-auto bg-gray-500 hover:bg-gray-700 rounded-lg shadow-xl font-medium text-white px-4 py-2'>Cancel</button> -->
                <button @click.prevent="trackApp" type="submit" class='w-auto bg-purple-500 hover:bg-purple-700 rounded-lg shadow-xl font-medium text-white px-4 py-2'>Track App</button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    components: {

    },
      data() {
        return {
            form: {
                reference_number: ''
            }
        };
    },
    created(){

    },
    methods: {
        trackApp(){
            axios.post("/trackapp", {
                reference_number: this.form.reference_number 
            }).then(res => {
                console.log(res);
                this.$router.push('/dashboard');
            });
        }
    }
}
</script>