<template>
    <div>
            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="mb-8 border-b border-gray-200">
                <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                    <div class="flex items-center justify-between flex-wrap">
                        <div class="w-0 flex-1 flex items-center">                            
                            <p class="ml-3 font-medium text-gray-700 truncate">
                                All Sessions
                            </p>
                        </div>
                        <div class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                            <router-link to="/session/create" class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-green-600 bg-white hover:bg-green-50">
                            New Session
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="flex flex-col">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-gray-50">
                                    <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        #
                                    </th>
                                   
                                   <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Year
                                    </th>

                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        <span class="sr-only">Edit</span>
                                    </th>

                                     <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        <span class="sr-only">Delete</span>
                                    </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr v-for="session in sessions" :key="session.id">
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            {{ session.id }}
                                        </td>
                                         <td class="px-6 py-4 whitespace-nowrap">
                                            {{ session.year }}
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            
                                            <router-link :to="{name: 'edit', params: {id: session.id}}" class="text-indigo-600 hover:text-indigo-900">Edit</router-link>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            
                                            <button @click="deleteSession(session.id)" class="text-indigo-600 hover:text-indigo-900">Delete</button>
                                        </td>
                                    </tr>

                                    <!-- More people... -->
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
        data() {
            return {
                sessions: [],
                showMessage: false,
                message: ''
            }
        },
        methods: {
            getSessions() {
                axios.get('sessions').then((response) => {
                    this.sessions = response.data.data
                    // console.log(response.data)
                }).catch((errors) => {
                    console.log(errors);
                });
            },

            deleteSession(id) {
                 axios.delete("/sessions/"+ id)
                    .then(res => {
                        this.showMessage = true;
                        this.message = res.data;
                        this.getSessions();
                    })
        
        }
    },

        mounted() {
            this.getSessions()
        }
    }
</script>
