<template>
    <div class="mb-4">
        <div class="flex flex-col h-screen bg-gray-100">
            <!-- Auth Card Container -->
            <div class="grid place-items-center mx-2 my-20 sm:my-auto">
                <!-- Auth Card -->
                <div class="w-11/12 p-12 sm:w-8/12 md:w-6/12 lg:w-5/12 2xl:w-4/12 
                    px-6 py-10 sm:px-10 sm:py-6 
                    bg-white rounded-lg shadow-md lg:shadow-lg">

                    <!-- Card Title -->
                    <h2 class="text-center font-semibold text-3xl lg:text-4xl text-gray-800">
                        Login
                    </h2>

                    <form @submit.prevent="handleSubmit">
                        <!-- Email Input -->
                        <label for="matric_number" class="block text-xs font-semibold text-gray-600 uppercase">Matric Number</label>
                        <input id="matric_number" type="matric_number" v-model="form.matric_number" placeholder="matric number" autocomplete="matric_number"
                            class="block w-full py-3 px-1 mt-2 
                            text-gray-800 appearance-none 
                            border-b-2 border-gray-100
                            focus:text-gray-500 focus:outline-none focus:border-gray-200"
                            required />

                        <!-- Password Input -->
                        <label for="password" class="block mt-2 text-xs font-semibold text-gray-600 uppercase">Password</label>
                        <input id="password" type="password" v-model="form.password" placeholder="password" autocomplete="current-password"
                            class="block w-full py-3 px-1 mt-2 mb-4
                            text-gray-800 appearance-none 
                            border-b-2 border-gray-100
                            focus:text-gray-500 focus:outline-none focus:border-gray-200"
                            required />

                        <!-- Auth Buttton -->
                        <button type="submit"
                            class="w-full py-3 mt-10 bg-gray-800 rounded-sm
                            font-medium text-white uppercase
                            focus:outline-none hover:bg-gray-700 hover:shadow-none">
                            Login
                        </button>

                        <!-- Another Auth Routes -->
                        <div class="sm:flex sm:flex-wrap mt-8 sm:mb-4 text-sm text-center">
                            <a href="forgot-password" class="flex-2 underline">
                                Forgot password?
                            </a>

                            <p class="flex-1 text-gray-500 text-md mx-4 my-1 sm:my-auto">
                                or
                            </p>
                
                            <a href="register" class="flex-2 underline">
                                Create an Account
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import axios from 'axios'
    import { mapActions } from 'vuex'

    export default {

        data() {
            return {
                form:{
                matric_number: '',
                password: ''
             }
            }
        },
        
        methods: {

              ...mapActions(['signIn']),
        async handleSubmit(){
          let res  = await this.signIn(this.form);
                // console.log(res.data);
          if(res.data.user.role_id){
            this.$router.push('/dashboard')
            // this.$router.go(-1)
          }else{
            console.log('Not Found')
          }
          //localStorage.setItem('token',res.data.token);
          console.log(res);
      }
            // async handleSubmit() {
            //     const response = await axios.post('login', {
            //         matric_number: this.matric_number,
            //         password: this.password
            //     });

            //    localStorage.setItem('token', response.data.token);
            //    this.$store.dispatch('user', response.data.user);
            //    this.$router.push('/dashboard');
            // }
        }
    }
</script>
