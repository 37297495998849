/*eslint-disable*/
export default function guest ({ next, store }){
    // console.log('guest')
    console.log(store.state.token)
    // if(store.state.user != null){
        if ((store.state.token != null) || (store.state.token!= undefined)  )  {
        return next({
           name: 'dashboard'
        })
    }

// }
   
    return next()
   }