<template>
    <div class="mb-4">
        <div class="flex flex-col h-screen bg-gray-100">
            <!-- Auth Card Container -->
            <div class="grid place-items-center mx-2 my-20 sm:my-auto">
                <!-- Auth Card -->
                <div class="w-11/12 p-12 sm:w-8/12 md:w-6/12 lg:w-5/12 2xl:w-4/12 
                    px-6 py-10 sm:px-10 sm:py-6 
                    bg-white rounded-lg shadow-md lg:shadow-lg">

                    <!-- Card Title -->
                    <h2 class="text-center font-semibold text-2xl lg:text-2xl text-gray-800">
                        Create request documents
                    </h2>

                    <form @submit.prevent="handleSubmit">
                        <!-- Email Input -->
                        <label for="name" class="block text-xs font-semibold text-gray-600 uppercase">name</label>
                        <input id="name" type="text" v-model="form.name" placeholder="name" autocomplete="name"
                            class="block w-full py-3 px-1 mt-2 
                            text-gray-800 appearance-none 
                            border-b-2 border-gray-100
                            focus:text-gray-500 focus:outline-none focus:border-gray-200"
                            required />

                        <!-- Auth Buttton -->
                        <button type="submit"
                            class="w-full py-3 mt-10 bg-gray-800 rounded-sm
                            font-medium text-white uppercase
                            focus:outline-none hover:bg-gray-700 hover:shadow-none">
                            Submit
                        </button>

                        <!-- Another Auth Routes -->
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {

        data() {
            return {
                form: {
                    name: ''
                }
            }
        },
        
        methods: {
            async handleSubmit() {
                await axios.post('requestdocuments', {
                name: this.form.name
          });

        //   console.log(response);
          this.$router.push('/request-document');
            }
        }
    }
</script>
