/*eslint-disable*/
export default function auth({ next, store }) {
    // console.log('google')
    console.log(store.state.token)
    if ((store.state.token == null) || (store.state.token == undefined)  )  {
        return next({
            name: 'login'
        })
    }

    return next()
}