import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './axios'
import "./assets/css/tailwind.css"
import VueSweetalert2 from 'vue-sweetalert2';
import Toasted from 'vue-toasted';
import store from './vuex'

// import Swal from 'sweetalert2'
// window.Swal = Swal

Vue.config.productionTip = false

Vue.use(VueSweetalert2);

Vue.use(Toasted, {
    duration: 5000
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')