<template>
    <div>

        <nav-bar></nav-bar>
        <router-view />
    </div>
</template>


<script>
    import NavBar from '@/components/Nav'

    export default {
        components: {
            NavBar,
                
        },
        
    }
</script>