<template>
    <div>
        <div class="flex h-screen bg-white-200 items-center justify-center  mt-32 mb-32">
            <div class="grid bg-white rounded-lg shadow-xl w-11/12 md:w-9/12 lg:w-1/2">

                <div class="flex justify-center">
                <div class="flex">
                    <h1 class="text-gray-600 font-bold md:text-2xl text-xl">Transcript Request Form</h1>
                </div>
                </div>

                <div class="grid grid-cols-1 mt-5 mx-7">
                    <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Full Name</label>
                    <input 
                    v-model="form.fullname" 
                    class="py-2 px-3 rounded-lg 
                    border-2 border-purple-300 
                    mt-1 focus:outline-none focus:ring-2 
                    focus:ring-purple-600 focus:border-transparent" type="text" placeholder="Full Name" />
                </div>

                 <div class="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 mt-5 mx-7">
                    <div class="grid grid-cols-1">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Department</label>
                        <select 
                        v-model="form.department_id"
                        name="name"
                        class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent">
                        
                            <option
                            v-for="department in departments" 
                            :key="department.id" 
                            :value="department.id">
                                {{ department.name }}
                            </option>
                        </select>
                    </div>
                    <div class="grid grid-cols-1">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Program Level</label>
                    <select 
                    v-model="form.programlevel_id"
                    name="name"
                    class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent">
                        
                        <option
                         v-for="programlevel in programlevels" 
                        :key="programlevel.id" 
                        :value="programlevel.id">
                            {{ programlevel.name }}
                        </option>
                    </select>
                    </div>
                 </div>

                 <div class="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 mt-5 mx-7">
                    <div class="grid grid-cols-1">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Class of Degree</label>
                    <select 
                    v-model="form.degreeclass_id"
                    name="name"
                    class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent">
                        
                         <option
                         v-for="classofdegree in classofdegrees" 
                        :key="classofdegree.id" 
                        :value="classofdegree.id">
                            {{ classofdegree.name }}
                        </option>
                    </select>
                    </div>
                    <div class="grid grid-cols-1">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Study Mode</label>
                    <select 
                    v-model="form.studymode_id"
                    name="name"
                    class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent">
                        
                         <option
                         v-for="studymode in studymodes" 
                        :key="studymode.id" 
                        :value="studymode.id">
                            {{ studymode.name }}
                        </option>
                    </select>
                    </div>
                </div>

                 <div class="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 mt-5 mx-7">
                    <div class="grid grid-cols-1">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Year of Entry</label>
                    
                    <datepicker v-model="form.entry_year" class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"></datepicker>
                    </div>
                    <div class="grid grid-cols-1">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Year of Graduation</label>
                   
                    <datepicker v-model="form.grad_year" class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"></datepicker>
                    </div>
                </div>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 mt-5 mx-7">
                    <div class="grid grid-cols-1">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Address 1</label>
                        <input v-model="form.address1" class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" type="text" placeholder="Address 1" />
                    </div>
                    <div class="grid grid-cols-1">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Address 2</label>
                        <input v-model="form.address2" class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" type="text" placeholder="Address 2" />
                    </div>
                </div>

                 <div class="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 mt-5 mx-7">
                    <fieldset class="relative z-0 w-full p-px mb-5">
                        <legend class="absolute text-gray-500 transform scale-75 -top-3 origin-0">Transcript Information</legend>
                        <div class="block pt-3 pb-2 space-x-4">
                        <label>
                            <input
                            v-model="form.copy"
                            type="radio"
                            name="radio"
                            value="soft"
                            class="mr-2 text-black border-2 border-gray-300 focus:border-gray-300 focus:ring-black"
                            />
                            Soft Copy
                        </label>
                        <label>
                            <input
                            v-model="form.copy"
                            type="radio"
                            name="radio"
                            value="hard"
                            class="mr-2 text-black border-2 border-gray-300 focus:border-gray-300 focus:ring-black"
                            />
                            Hard Copy
                        </label>
                        </div>
                        <!-- <span class="text-sm text-red-600 hidden" id="error">Option has to be selected</span> -->
                    </fieldset>
                 </div>

                 <div class="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 mt-5 mx-7">
                    <div class="grid grid-cols-1">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Transcript Destination</label>
                    <select 
                    v-model="form.destination"
                    class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent">
                        <option value="local">Local</option>
                        <option value="international">International</option>
                    </select>
                    </div>
                    <div class="grid grid-cols-1">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Reason for Request</label>
                    <select 
                    v-model="form.reason_for_request"
                    class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent">
                        <option value="admission">Admission</option>
                        <option value="job">Job</option>
                        <option value="scholarship">Scholarship</option>
                    </select>
                    </div>
                </div>

                 <div class="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 mt-5 mx-7">
                    <div class="grid grid-cols-1">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Destination Institution/School Name</label>
                        <input v-model="form.destination_institution" class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" type="text" placeholder="Destination Institution" />
                    </div>
                    <div class="grid grid-cols-1">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Destination School Address</label>
                        <input v-model="form.destination_address" class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" type="text" placeholder="Destination School Address" />
                    </div>
                </div>


                 <div class="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 mt-5 mx-7">
                    <div class="grid grid-cols-1">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Country</label>
                    <select 
                     v-model="form.country_id"
                        @change="getStates()"
                        name="country" 
                    class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent">
                        <option
                        v-for="country in countries" 
                        :key="country.id" 
                        :value="country.id">
                        {{ country.name }}
                        </option>
                    </select>
                    </div>
                    <div class="grid grid-cols-1">
                        <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">State</label>
                    <select 
                    v-model="form.state_id"
                        name="state"
                    class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent">
                        <option
                        v-for="state in states" 
                        :key="state.id" 
                        :value="state.id">
                        {{ state.name }}
                        </option>
                    </select>
                    </div>
                </div>

                 <div class="grid grid-cols-1 mt-5 mx-7">
                    <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Destination Contact Number</label>
                    <input v-model="form.destination_contact_number" class="py-2 px-3 rounded-lg border-2 border-purple-300 mt-1 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" type="text" placeholder="Destination Contact Number" />
                </div>

                 <!-- <div class="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 mt-5 mx-7">
                    <fieldset class="relative z-0 w-full p-px mb-5">
                        <legend class="absolute text-gray-500 transform scale-75 -top-3 origin-0">Choose a Shipping Company</legend>
                        <div class="block pt-3 pb-2 space-x-4">
                        <label>
                            <input
                            type="radio"
                            name="radio"
                            value="1"
                            class="mr-2 text-black border-2 border-gray-300 focus:border-gray-300 focus:ring-black"
                            />
                            Include english proficiency request
                        </label>
                        
                        </div>
                        <span class="text-sm text-red-600 hidden" id="error">Option has to be selected</span>
                    </fieldset>
                 </div> -->

                 <div class="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-8 mt-5 mx-7">
                    <fieldset class="relative z-0 w-full p-px mb-5">
                        <legend class="absolute text-gray-500 transform scale-75 -top-3 origin-0">Choose a Shipping Company</legend>
                        <div class="block pt-3 pb-2 space-x-4">
                        <label>
                            <input
                            v-model="form.deliverymethod_id"
                            type="radio"
                            name="deliveryType"
                            value="1"
                            class="mr-2 text-black border-2 border-gray-300 focus:border-gray-300 focus:ring-black"
                            />
                            UPS   
                        </label>
                        <label>
                            <input
                            v-model="form.deliverymethod_id"
                            type="radio"
                            name="deliveryType"
                            value="2"
                            class="mr-2 text-black border-2 border-gray-300 focus:border-gray-300 focus:ring-black"
                            />
                            DHL  
                        </label>
                        </div>
                        <!-- <span class="text-sm text-red-600 hidden" id="error">Option has to be selected</span> -->
                    </fieldset>
                 </div>

                <div class='flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5'>
                <!-- <button class='w-auto bg-gray-500 hover:bg-gray-700 rounded-lg shadow-xl font-medium text-white px-4 py-2'>Cancel</button> -->
                <button @click.prevent="storeTranscript" type="submit" class='w-auto bg-purple-500 hover:bg-purple-700 rounded-lg shadow-xl font-medium text-white px-4 py-2'>Submit Transcript Request</button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from "moment";
import Datepicker from 'vuejs-datepicker';

export default {
    components: {
        Datepicker
    },
      data() {
        return {
            countries: [],
            states: [],
            // cities: [],
            departments: [],
            sessions: [],
            programlevels: [],
            deliverymethods: [],
            classofdegrees: [],
            studymodes: [],
            form: {
                fullname: '',
                address1: '',
                address2: '',
                user_id: '',
                department_id: '',
                programlevel_id: '',
                degreeclass_id: '',
                studymode_id: '',
                session_id: '',
                deliverymethod_id: '',
                country_id: '',
                state_id: '',
                entry_year: '',
                grad_year: '',
                copy: '',
                destination: '',
                reason_for_request: '',
                destination_institution: '',
                destination_address: '',
                destination_contact_number: '',
                reference_number: '',
                status: 0
            }
        };
    },
    created(){
        this.getCountries();
        this.getStates();
        this.getDepartments();
        this.getProgramLevels();
        this.getClassOfDegrees();
        this.getStudyModes();

        // const response = await axios.get('user');

        //     // console.log(response);
        //     this.user = response.data
    },
    methods: {
        getCountries(){
            axios.get("/transcript/countries")
                 .then(res => {
                    this.countries = res.data;
                }).catch(error => {
                    console.log(error)
                });
        },
        getStates(){
            axios.get("/transcript/"+ this.form.country_id + "/states")
                 .then(res => {
                     console.log(this.form.country_id);
                    this.states = res.data;
                }).catch(error => {
                    console.log(error)
                });
        },
        getDepartments(){
            axios.get("/transcript/departments")
                 .then(res => {
                    this.departments = res.data;
                }).catch(error => {
                    console.log(error)
                });
        },
        getProgramLevels(){
            axios.get("/transcript/programlevels")
                 .then(res => {
                    this.programlevels = res.data;
                }).catch(error => {
                    console.log(error)
                });
        },
        getClassOfDegrees(){
            axios.get("/transcript/classofdegrees")
                 .then(res => {
                    this.classofdegrees = res.data;
                }).catch(error => {
                    console.log(error)
                });
        },
        getStudyModes(){
            axios.get("/transcript/studymodes")
                 .then(res => {
                    this.studymodes = res.data;
                }).catch(error => {
                    console.log(error)
                });
        },
        storeTranscript(){
            axios.post("/transcript", {
                fullname: this.form.fullname,
                address1: this.form.address1,
                address2: this.form.address2,
                department_id: this.form.department_id,
                country_id: this.form.country_id,
                state_id: this.form.state_id,
                programlevel_id: this.form.programlevel_id,
                degreeclass_id: this.form.degreeclass_id,
                studymode_id: this.form.studymode_id,
                session_id: this.form.session_id,
                deliverymethod_id: this.form.deliverymethod_id,
                entry_year: this.format_date(this.form.entry_year),
                grad_year: this.format_date(this.form.grad_year),
                copy: this.form.copy,
                destination: this.form.destination,
                reason_for_request: this.form.reason_for_request,
                destination_institution: this.form.destination_institution,
                destination_address: this.form.destination_address,
                destination_contact_number: this.form.destination_contact_number,
                reference_number: this.form.reference_number =  'T' + Math.floor(Math.random()*(9999999999-1000000000+1)+1000000000)
            }).then(res => {
                console.log(res);
                this.$router.push('/dashboard');
            });
        },

        format_date(value){
            if (value){
                return moment(String(value)).format("YYYY-MM-DD");
            }
        },
    }
}
</script>