<template>
    <div>
        <div class="flex h-screen bg-white-200 items-center justify-center  mt-32 mb-32">
            <div class="grid bg-white rounded-lg shadow-xl w-11/12 md:w-9/12 lg:w-1/2">

                <div class="flex justify-center">
                <div class="flex">
                    <h1 class="text-gray-600 font-bold md:text-2xl text-xl">Transcript Details</h1>
                </div>
                </div>
                <div class="grid grid-cols-1 mt-5 mx-7">
                    <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Name</label>
                    <input 
                    v-model="transcriptdetails.fullname" 
                    class="py-2 px-3 rounded-lg 
                    border-2 border-purple-300 
                    mt-1 focus:outline-none focus:ring-2 
                    focus:ring-purple-600 focus:border-transparent" type="text" placeholder="Name" />
                </div>

                <div class="grid grid-cols-1 mt-5 mx-7">
                   <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">Address</label>
                    <input 
                    v-model="transcriptdetails.address1" 
                    class="py-2 px-3 rounded-lg 
                    border-2 border-purple-300 
                    mt-1 focus:outline-none focus:ring-2 
                    focus:ring-purple-600 focus:border-transparent" type="text" placeholder="Address" />
                </div>
                <div class="grid grid-cols-1 mt-5 mx-7">
                   <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">School</label>
                    <input 
                    v-model="transcriptdetails.destination_institution" 
                    class="py-2 px-3 rounded-lg 
                    border-2 border-purple-300 
                    mt-1 focus:outline-none focus:ring-2 
                    focus:ring-purple-600 focus:border-transparent" type="text" placeholder="School" />
                </div>

                <div class="grid grid-cols-1 mt-5 mx-7 mb-10">
                   <label class="uppercase md:text-sm text-xs text-gray-500 text-light font-semibold">School Address</label>
                    <input 
                    v-model="transcriptdetails.destination_address" 
                    class="py-2 px-3 rounded-lg 
                    border-2 border-purple-300 
                    mt-1 focus:outline-none focus:ring-2 
                    focus:ring-purple-600 focus:border-transparent" type="text" placeholder="School Address" />
                </div>           

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    components: {
        
    },
      data() {
        return {
           transcriptdetails: []
        };
    },
    created(){
        this.getTranscriptDetails();
    },
    methods: {
        getTranscriptDetails(){
            axios.get("/transcriptdetails")
                 .then(res => {
                    this.transcriptdetails = res.data.data[0];
                    // console.log(this.transcriptdetails);
                }).catch(error => {
                    console.log(error)
                });
        },
       
    }
}
</script>