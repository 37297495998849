<template>
    <div>
            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="mb-8 border-b border-gray-200">
                <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                    <div class="flex items-center justify-between flex-wrap">
                        <div class="w-0 flex-1 flex items-center">                            
                            <p class="ml-3 font-medium text-gray-700 truncate">
                                Certificate Request
                            </p>
                        </div>
                        <div class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                            <!-- <router-link to="/request-type/create" class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-green-600 bg-white hover:bg-green-50">
                            New Request Type
                            </router-link> -->
                        </div>
                    </div>
                </div>
            </div>

            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="flex flex-col">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead class="bg-gray-50">
                                    <tr>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        #
                                    </th>
                                   
                                   <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Request Type
                                    </th>

                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Destination Institution
                                    </th>
                                     <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Address
                                    </th>
                                     <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Country
                                    </th>
                                     <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        State
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        City
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Zip Code
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Destination Contact Number
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Reference Number
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Status
                                    </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white divide-y divide-gray-200">
                                    <tr  v-for="allcertificateresquest in allcertificateresquests" :key="allcertificateresquest.id">
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            {{ allcertificateresquest.id }}
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            {{ allcertificateresquest.requesttype.name }} #{{ allcertificateresquest.requesttype.amount }}
                                        </td>

                                        <td class="px-6 py-4 whitespace-nowrap">
                                            {{ allcertificateresquest.address1 }}
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            {{ allcertificateresquest.destination_institution }}
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            {{ allcertificateresquest.country.name }}
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            {{ allcertificateresquest.state }}
                                        </td>

                                        <td class="px-6 py-4 whitespace-nowrap">
                                            {{ allcertificateresquest.city }}
                                        </td>

                                        <td class="px-6 py-4 whitespace-nowrap">
                                            {{ allcertificateresquest.zipcode }}
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap">
                                            {{ allcertificateresquest.destination_contact_number }}
                                        </td>

                                        <td class="px-6 py-4 whitespace-nowrap">
                                            {{ allcertificateresquest.reference_number }}
                                        </td>

                                        <td class="px-6 py-4 whitespace-nowrap">
                                            {{ allcertificateresquest.status }}
                                        </td>
                                        <!-- <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            
                                            <router-link :to="{name: 'editrtype', params: {id: requesttype.id}}" class="text-indigo-600 hover:text-indigo-900">Edit</router-link>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            
                                            <button @click="deleteRequestType(requesttype.id)" class="text-indigo-600 hover:text-indigo-900">Delete</button>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            allcertificateresquests: [],
            // showMessage: false,
            // message: ''
        }
    },
    methods: {
        getAllCertificateRequest() {
            axios.get('certificate-request').then((response) => {
                this.allcertificateresquests = response.data.data
                // console.log(response.data.data)
            }).catch((errors) => {
                console.log(errors);
            });
        },
},
    mounted() {
        this.getAllCertificateRequest()
    }
}
</script>
